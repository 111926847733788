/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Ubuntu me estaba dando muchos problemas con la targeta grafica y los drivers por eso he decidido volver a Debian y como ya esta disponible la nueva version estable Lenny no me lo he pensado dos veces, por suerte en esta versión ya no está el fallo que me confundia los discos IDE con los SATA. Ahora solo queda configurarlo para que todo esté a mi gusto. Un saludo");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
